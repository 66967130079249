import React from 'react';
import { getFullType, getLocaleDateString } from '../../../helpers/main';
import DownloadZone from '../../DownloadZone';

const TableItem = ({ source, cardRef, isLast }) => {
  const ref = isLast ? { ref: cardRef } : {};
  const { name, developer, type, square, startTimeProject, region } = source;
  return (
    <div className="table__box" {...ref}>
      <div className="table__20">
        <div className="table__main-text wizard__code-plus ">{name}</div>
      </div>
      <div className="table__20">
        <div className="table__main-text">{developer}</div>
      </div>
      <div className="table__15">
        <div className="table__main-text">{getFullType(type)}</div>
      </div>
      <div className="table__10">
        <div className="table__main-text">{square}</div>
      </div>
      <div className="table__10">
        <DownloadZone
          buttonText="Download Zone"
          coordinate={source}
          fileName={`ofp_${name}_Zone`}
        />
      </div>
      <div className="table__10">
        <div className="table__main-text">
          {getLocaleDateString(startTimeProject)}
        </div>
      </div>
      <div className="table__15">
        <div className="table__main-text">{region}</div>
      </div>
    </div>
  );
};

export default TableItem;
