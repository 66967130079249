import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { CSVLink } from 'react-csv';
import FieldText from '../../generic/FieldText';
import ProjectDocumentation from '../../ProjectDocumentation';
import Button from '../../../../generic/Button';
import getConfig from '../../../../config';
import MapMini from '../../../../components/Map/MapMini';
import { makeCvsByValueField } from '../../../../utils/helpers';

const EvaluatePage = ({ step, setStep, projectData, carbonFormData }) => {
  const history = useHistory();
  if (step !== 0) {
    return null;
  }
  const noData = '- Unavailable until project start -';
  const { projectDurationType } = getConfig();

  const {
    developer = '',
    type = '',
    name = '',
    region = '',
    codePlus = '',
    square = '',
    aboutProject = '',
    goals = '',
    masters = '',
    benefits = '',
    avgTrees = '',
    treeSpecies = '',
    filesCidDir,
    iconCidDir = '',
  } = projectData;

  const getDuration = () => {
    const { projectDuration = '', startTimeProject = 0 } = projectData;
    if (startTimeProject) {
      let endProject;
      const startProject = new Date(startTimeProject / 1e6).getFullYear();
      const currentYear = new Date().getFullYear();

      if (projectDurationType === 'year') endProject = startProject + +projectDuration;
      if (projectDurationType === 'day') endProject = startProject + Math.floor(+projectDuration / 365);

      const totalDuration = `${startProject} - ${endProject}`;
      const remainingDuration = currentYear > endProject ? noData : `${currentYear} - ${endProject}`;
      return { totalDuration, remainingDuration };
    }
    return { totalDuration: noData };
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { totalDuration } = useMemo(getDuration, []);
  const filteredFormData = [{ ...carbonFormData.carbonFormInfo }, carbonFormData.carbonFormPrivateInfo?.fields];
  const data = makeCvsByValueField(...filteredFormData).map((row) => ({
    'Field name': row['Field name'].trim().replace(/&/g, 'and').replace(/[:?]/g, ''),
    'Field Value': row['Field Value'] ? row['Field Value'].replace(/\n/g, ' ') : '',
  }));

  return (
    <div className="eval__block">
      <div className="eval__csv-wrapper">
        <CSVLink
          data={
              data
            }
          filename="CarbonInformation"
          className="eval__csv btn"
        >
          Download Carbon Information
        </CSVLink>
      </div>

      <div className="eval__block">

        <div className="eval__block-wrapper">
          <FieldText label="Organisation Name" value={developer} customClass="half" />
          <FieldText customClass="half capitalize" label="Project Type" value={type} />
          <FieldText label="Project Name" value={name} customClass="half" />
          <FieldText label="Expected Project Duration (Years)" value={totalDuration} customClass="half" />
          <ProjectDocumentation
            iconCidDir={iconCidDir}
            filesCidDir={filesCidDir}
          />
        </div>
      </div>
      <div className="eval__block">
        <div className="eval__block-title">
          Project Location
        </div>
        <div className="eval__block-wrapper">
          <FieldText label="Location" value={region} customClass="half" />
          <FieldText label="Google Plus Code" value={codePlus} customClass="half" />
          <FieldText label="Project Area (ha)" value={square} customClass="half" />
          <div className="eval__modal-map">
            <MapMini state={projectData} />
          </div>
        </div>
      </div>
      <div className="eval__block">
        <div className="eval__block-title">
          Additional Information
        </div>
        <div className="eval__block-wrapper">
          <FieldText label="Project Description" value={aboutProject} customClass="full" />
          <FieldText label="Project Goals" value={goals} customClass="full" />
          <FieldText label="Stakeholders and local communities involved in the project" value={masters} customClass="half" />
          <FieldText label="Co-benefits" value={benefits} customClass="half" />
          <FieldText label="Average Tree Density (trees/ha)" value={avgTrees} customClass="half" />
          <FieldText label="Tree Species " value={treeSpecies} customClass="half one-line" />
          <div className="eval__wrapper-btns">
            <Button className="cancel" onClick={() => history.push('/')}>Back </Button>
            <Button onClick={() => setStep(1)}>Next</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluatePage;
