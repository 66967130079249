import * as nearAPI from 'near-api-js';
import { getPKey } from './near-utils';
import getConfig from '../config';
import { risksParams } from '../constans/constans';

const { nodeUrl } = getConfig();

export const notMoreHundred = (val) => (+val > 100 ? 100 : val);

export const helperSettingForBunchTransaction = async (connect, connectionConfig, WalletConnection, account) => {
  const nearConnection = await connect(connectionConfig);
  const walletConnection = new WalletConnection(nearConnection);
  const storageKey = getPKey(account?.accountId);
  const keyPair = nearAPI.utils.key_pair.KeyPairEd25519.fromString(storageKey);
  const signerPublicKey = keyPair.getPublicKey();
  const provider = new nearAPI.providers.JsonRpcProvider(nodeUrl);
  const sender = account?.accountId;
  const accessKey = await provider.query(`access_key/${sender}/${signerPublicKey.toString()}`, '');
  const nonce = ++accessKey.nonce;
  const recentBlockHash = nearAPI.utils.serialize.base_decode(accessKey.block_hash);

  return { walletConnection, signerPublicKey, sender, nonce, recentBlockHash };
};
const multiField = (text, array) => {
  const arrayResult = [];
  // eslint-disable-next-line array-callback-return
  array.map((el, index) => {
    const subArray = Object.entries(el).map((elem) => {
      if (typeof elem[1] === 'object') {
        return ({ 'Field name': `${text}:${elem[0]}-${index + 1}`, 'Field Value': elem[1]?.label });
      }
      return ({ 'Field name': `${text}:${elem[0]}-${index + 1}`, 'Field Value': elem[1] });
    });

    arrayResult.push(subArray);
  });
  // eslint-disable-next-line no-unreachable
  return arrayResult;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const makeCvsByValueField = (carbonForm, privatDate) => {
  const { generalInfo, projectActivity, landInfo, legalAndFinancial, risks } = carbonForm;
  const { isGhgCarbon, ghgCarbonPrograms, country, projectSize,
    communitiesAndStakeholders, subOrTropicalTrees, mainTypeOfActivity, treeDensity, isRelativelyHomogeneous,
    positiveImpactsDescription, yearsInvolved, continent, startPlantingActivities, durationForPlanting, forestType } = generalInfo;
  const { generalPlanning, sitePreparationActivities, willZoneBeBurned, arePestWeedControlUsed,
    pestWeedProducts, seedlingsComeFrom, typeOfTreePlantingActivities, areFertilizersUsed, fertilizersProducts, soilDisturbanceHappen, isPruningPlanned,
    isThinningPlanned, areExoticSpeciesPlanted, exoticSpeciesList, measuresPutInPlace, intendToDoWithForest, continueLightGroundMonitoring, treeSpeciesNumber } = projectActivity;
  const {
    whoOwnsTheLand,
    landUseOfProjectZone,
    wasProjectZoneCleared,
    howLandWasCleared,
    currentLandUse,
    commerciallyUsed,
    howZoneIsUsedByCommunities,
    involvementInLivelihoodsPrograms,
    engagedInAlternativeOptions,
    landUseIfZoneNotImplemented,
    isLocatedOnPeatlandsOrWetlands,
    includeExistingScatteredTrees,
  } = landInfo;
  const { needGovernmentApprove,
    envImpactAssessment,
    summaryOfImpacts,
    areActivitiesLegalRequirement,
    activitiesRequirementExplanation,
    isTimberHarvestingPlanned,
    timberHarvestingExplanation,
    projectFinancing,
    financingReceived,
    enoughFundingToProtect,
    viableWithoutCarbonFinancing,
    willOFPHelp,
    howOFPHelps } = legalAndFinancial;
  const {
    natural,
    socialAndPolitical,
    projectManagement,
    financial,
  } = risks;
  const {
    fire,
    pestsAndDiseases,
    extremeEvents,
    extremeDroughts,
    animalEncroachment,
  } = natural;
  const {
    disputeOverLandTenureOrOwnership,
    politicalAndSocialInstability,
    exploitationOfNaturalResources,
    lackOfCommunitySupport,
  } = socialAndPolitical;
  const {
    insufficientTechnicalCapacities,
    lackOfEquipmentAndMaterial,
    lackOfProjectManagement,
    relianceOnKeyEmployees,
  } = projectManagement;
  const {
    comments,
    forestMonitoring = {
      email: '',
      organisation: '',
      personName: '',
      phoneNumber: '',
    },
    projectManagement: projectManagementType = {
      email: '',
      organisation: '',
      personName: '',
      phoneNumber: '',
    },
    treePlanting = {
      email: '',
      organisation: '',
      personName: '',
      phoneNumber: '',
    },
  } = privatDate;

  const { lackOfSufficientFunding = {} } = financial;
  const pestWeedProductsFields = multiField('2.5 If yes, please detail the products, quantity (tonnes of product/ha) and frequency.', pestWeedProducts).flat();
  const fertilizersProductsFields = multiField('2.9 If yes, please detail the products, quantity (tonnes of product/ha) and frequency.', fertilizersProducts).flat();
  return [
    { 'Field name': '1.1 How long has the organisation been involved in forest activities (years)?', 'Field Value': yearsInvolved?.label },
    { 'Field name': '1.2 Is the project registered (or seeking registration) under other GHG/carbon program(s) than Open Forest Protocol?\n', 'Field Value': isGhgCarbon === 'true' ? 'Yes' : 'No' },
    { 'Field name': '1.3 If yes, under which program(s) ?', 'Field Value': ghgCarbonPrograms },
    { 'Field name': '1.4 In which continent is the project located?', 'Field Value': continent?.label },
    { 'Field name': '1.5 In which country is the project located?', 'Field Value': country },
    { 'Field name': '1.6 When did or when will the tree planting activities start?', 'Field Value': startPlantingActivities?.value },
    { 'Field name': '1.7 What is the expected project duration for planting the trees, taking care of the project and monitoring the tree/forest growth? (years)', 'Field Value': durationForPlanting?.value },
    { 'Field name': '1.8 What is the project size (ha)?', 'Field Value': projectSize },
    { 'Field name': '1.9 Has the project (design and implementation) been presented (including consultation) to local communities and stakeholders ? If yes, when and with how many participants?', 'Field Value': communitiesAndStakeholders },
    { 'Field name': '1.10 What is the project’s forest type?\n', 'Field Value': forestType?.label },
    { 'Field name': '1.11 If the project is a tropical or subtropical forest, what is the main type of trees?\n', 'Field Value': subOrTropicalTrees && capitalizeFirstLetter(subOrTropicalTrees) },
    { 'Field name': '1.12 What is the project’s main type of activity?\n', 'Field Value': mainTypeOfActivity },
    { 'Field name': '1.13 What is the average tree density (trees/ha) in the project zone?\n', 'Field Value': treeDensity },
    { 'Field name': '1.14 Is the tree density relatively homogeneous over the entire project zone?\n', 'Field Value': isRelativelyHomogeneous === 'true' ? 'Yes' : 'No' },
    { 'Field name': '1.15 Describe the positive impacts of the project activities on ecosystems, biodiversity and local livelihoods.\n', 'Field Value': positiveImpactsDescription },
    { 'Field name': '2.1 Describe the general planning of the project.\n', 'Field Value': generalPlanning },
    { 'Field name': '2.2 Describe the site preparation activities.\n', 'Field Value': sitePreparationActivities },
    { 'Field name': '2.3 Will the project zone be burned (for site preparation or as part of forest management) prior to the project start? If yes, please explain.\n', 'Field Value': willZoneBeBurned },
    { 'Field name': '2.4 Are pest and weed control products used?\n', 'Field Value': arePestWeedControlUsed === 'true' ? 'Yes' : 'No' },
    ...pestWeedProductsFields,
    { 'Field name': '2.6 Where do seedlings come from?\n', 'Field Value': seedlingsComeFrom },
    { 'Field name': '2.7 Describe the type of tree planting activities? How is the plantation / regeneration done? (manually, with machines, with drones, etc.).', 'Field Value': typeOfTreePlantingActivities },
    { 'Field name': '2.8 Are fertilizers used?\n', 'Field Value': areFertilizersUsed === 'true' ? 'Yes' : 'No' },
    ...fertilizersProductsFields,
    { 'Field name': '2.10 Will soil disturbance (soil compaction due to machines, etc.) happen on more than 10% of the project zone? If so, please explain why.', 'Field Value': soilDisturbanceHappen },
    { 'Field name': '2.11 Is pruning planned? If yes, please explain.\n', 'Field Value': isPruningPlanned },
    { 'Field name': '2.12 Is thinning planned? If yes, please explain.\n', 'Field Value': isThinningPlanned },
    { 'Field name': '2.13 How many tree species are you planting in the project zone?\n', 'Field Value': treeSpeciesNumber },
    { 'Field name': '2.14 Are exotic / non-native tree species planted?\n', 'Field Value': areExoticSpeciesPlanted === 'true' ? 'Yes' : 'No' },
    { 'Field name': '2.15 If yes, please list the non-native species.?\n', 'Field Value': exoticSpeciesList },
    { 'Field name': '2.16 What are the measures put in place to make sure the forest will still be in the ground in at least 40 years from now ?\n', 'Field Value': measuresPutInPlace },
    { 'Field name': '2.17 At the end of the project, what do you intend to do with the standing forest?\n', 'Field Value': intendToDoWithForest },
    { 'Field name': '2.18 OFP will provide financial support for forest conservation (once the forest is mature). Would you agree on continuing a light ground monitoring to ensure the forest\'s ongoing conservation?\n', 'Field Value': continueLightGroundMonitoring === 'true' ? 'Yes' : 'No' },
    { 'Field name': '3.1 Who owns the land? If the land is leased, are you in possession of a convention?', 'Field Value': whoOwnsTheLand },
    { 'Field name': '3.2 What was the land use of the project zone in the past 10 years prior to the project start?', 'Field Value': landUseOfProjectZone },
    { 'Field name': '3.3 Was the project zone cleared and deforested in the past 10 years prior to the project start?', 'Field Value': wasProjectZoneCleared === 'true' ? 'Yes' : 'No' },
    { 'Field name': '3.4 If yes, please explain when why and how the land was cleared.', 'Field Value': howLandWasCleared },
    { 'Field name': '3.5 What is the land use of the project zone right before the start of the project?', 'Field Value': currentLandUse },
    { 'Field name': '3.6 Are/were local communities commercially using the project zone prior to the project start?', 'Field Value': commerciallyUsed === 'true' ? 'Yes' : 'No' },
    { 'Field name': '3.7 If yes, please explain how the project zone was/is used by local communities.', 'Field Value': howZoneIsUsedByCommunities },
    { 'Field name': '3.8 Does the project include an alternative livelihoods program? If yes, please explain the involvement of the local communities in the programs.', 'Field Value': involvementInLivelihoodsPrograms },
    { 'Field name': '3.9 If yes, has the local community already engaged in the alternative livelihood options and how?', 'Field Value': engagedInAlternativeOptions },
    { 'Field name': '3.10 What would be the land use in the project zone if the project  would not be implemented? The projected scenario should be compatible with current laws and regulations.', 'Field Value': landUseIfZoneNotImplemented },
    { 'Field name': '3.11 Is the project located on peatlands / wetlands (tidal wetlands included) and could result in the manipulation of the water table?', 'Field Value': isLocatedOnPeatlandsOrWetlands },
    { 'Field name': '3.12 Prior to project start, did the project zone include previously existing scattered trees?', 'Field Value': includeExistingScatteredTrees === 'true' ? 'Yes' : 'No' },
    { 'Field name': '4.1 Does the project need to be approved by the government to comply with national laws and regulations?', 'Field Value': needGovernmentApprove === 'true' ? 'Yes' : 'No' },
    { 'Field name': '4.2 Is an Environmental Impact Assessment legally required?', 'Field Value': envImpactAssessment === 'true' ? 'Yes' : 'No' },
    { 'Field name': '4.3 If yes, please provide a summary of the impacts and mitigation measures.', 'Field Value': summaryOfImpacts },
    { 'Field name': '4.4 In the project zone, are reforestation / afforestation / revegetation / conservation activities a legal requirement (required by law, regulatory framework, etc.) ?', 'Field Value': areActivitiesLegalRequirement === 'true' ? 'Yes' : 'No' },
    { 'Field name': '4.5 If yes, please explain.', 'Field Value': activitiesRequirementExplanation === 'true' ? 'Yes' : 'No' },
    { 'Field name': '4.6 Is timber harvesting planned in the project zone?', 'Field Value': isTimberHarvestingPlanned === 'true' ? 'Yes' : 'No' },
    { 'Field name': '4.7 If yes, please explain in more details what is planned in terms of timber harvesting.', 'Field Value': timberHarvestingExplanation },
    { 'Field name': '4.8 How is the project financed ?', 'Field Value': projectFinancing },
    { 'Field name': '4.9 How much financing has been received up until now and for what activities? Please indicate the currency used.', 'Field Value': financingReceived },
    { 'Field name': '4.10 Is there enough funding secured to protect the forest over the long term (more than to 40 years) without taking into account the revenue from carbon credits?', 'Field Value': enoughFundingToProtect },
    { 'Field name': '4.11 Can the project be economically and financially viable without access to carbon finance? Please explain.', 'Field Value': viableWithoutCarbonFinancing },
    { 'Field name': '4.12 Will the use of Open Forest Protocol help your project overcome a potential technological barrier to implementing or continuing this project?', 'Field Value': willOFPHelp === 'true' ? 'Yes' : 'No' },
    { 'Field name': '4.13 Description of how OFP helps this project overcome a technological barrier.', 'Field Value': howOFPHelps },
    { 'Field name': '5.1.1 Damage of the project zone by a fire', 'Field Value': `${risksParams[fire?.rating]}.Description:${fire?.description}` },
    { 'Field name': '5.1.2 Damage of the project zone by pests and diseases', 'Field Value': `${risksParams[pestsAndDiseases?.rating]}.Description:${pestsAndDiseases?.description}` },
    { 'Field name': '5.1.3 Damage of the project zone by extreme events such as hurricanes, earthquakes, volcanoes, etc.', 'Field Value': `${risksParams[extremeEvents?.rating]}.Description:${extremeEvents?.description}` },
    { 'Field name': '5.1.4 Damage of the project zone due to extreme droughts, floods, landslides, etc.', 'Field Value': `${risksParams[extremeDroughts?.rating]}.Description:${extremeDroughts?.description}` },
    { 'Field name': '5.1.5 Damage of the project zone due to animal encroachment (wild and domestic animals)', 'Field Value': `${risksParams[animalEncroachment?.rating]}.Description:${animalEncroachment?.description}` },
    { 'Field name': '5.2.1 Project failure due to dispute over land tenure or ownership of land rights in the project zone (including expropriation risks)', 'Field Value': `${risksParams[disputeOverLandTenureOrOwnership?.rating]}.Description:${disputeOverLandTenureOrOwnership?.description}` },
    { 'Field name': '5.2.2 Project failure due to political and social instability such as wars, riots, corruption, community resistance', 'Field Value': `${risksParams[politicalAndSocialInstability?.rating]}.Description:${politicalAndSocialInstability?.description}` },
    { 'Field name': '5.2.3 Project failure due to exploitation of natural resources in or in the vicinity of the project zone (timber, mining, water, oil, etc.)', 'Field Value': `${risksParams[exploitationOfNaturalResources?.rating]}.Description:${exploitationOfNaturalResources?.description}` },
    { 'Field name': '5.2.4 Project failure due to lack of community support for the project', 'Field Value': `${risksParams[lackOfCommunitySupport?.rating]}.Description:${lackOfCommunitySupport?.description}` },
    { 'Field name': '5.3.1 Project failure due to insufficient technical capacities (reliance on seasonal workers / workers needing constant training, etc.)', 'Field Value': `${risksParams[insufficientTechnicalCapacities?.rating]}.Description:${insufficientTechnicalCapacities?.description}` },
    { 'Field name': '5.3.2 Project failure due to lack of technical equipment and planting material (machinery, seedlings, etc.)', 'Field Value': `${risksParams[lackOfEquipmentAndMaterial?.rating]}.Description:${lackOfEquipmentAndMaterial?.description}` },
    { 'Field name': '5.3.3 Project failure due to lack of project management and long term financial planning skills', 'Field Value': `${risksParams[lackOfProjectManagement?.rating]}.Description:${lackOfProjectManagement?.description}` },
    { 'Field name': '5.3.4 Project failure due to the reliance of the project’s success on key employees that are hard to replace', 'Field Value': `${risksParams[relianceOnKeyEmployees?.rating]}.Description:${relianceOnKeyEmployees?.description}` },
    { 'Field name': '5.4.1 Project failure due to the lack of sufficient funding to support the project activities until carbon finance can be received (3-4 years after the trees are planted)', 'Field Value': `${risksParams[lackOfSufficientFunding?.rating]}.Description:${lackOfSufficientFunding?.description}` },
    { 'Field name': '7.1 Who is responsible for the project management?', 'Field Value': `Organisation name:${projectManagementType?.organisation}.Email":${projectManagementType?.email}.Contact person name:${projectManagementType?.personName}.Telephone":${projectManagementType?.phoneNumber}` },
    { 'Field name': '7.2 Who is/will be responsible for the tree planting activities?', 'Field Value': `Organisation name:${treePlanting?.organisation}.Email":${treePlanting?.email}.Contact person name:${treePlanting?.personName}.Telephone":${treePlanting?.phoneNumber}` },
    { 'Field name': '7.3 Who is/will be responsible for the forest monitoring?', 'Field Value': `Organisation name:${forestMonitoring?.organisation}.Email":${forestMonitoring?.email}.Contact person name:${forestMonitoring?.personName}.Telephone":${forestMonitoring?.phoneNumber}` },
    { 'Field name': 'Comments', 'Field Value': comments },
  ];
};

export const makeCsvForCarbonProjects = (data, typeOfProject) => {
  const projects = [];
  // eslint-disable-next-line array-callback-return
  data.map((el) => {
    // eslint-disable-next-line no-shadow
    const { name, developer, type, project_id, region, square, carbonApplication } = el;
    const startProject = new Date(+project_id).toLocaleString();
    const whiteListDate = new Date(+carbonApplication?.submittedAt / 1e6).toLocaleString();
    const startDate = el?.startTimeProject ? new Date(+el?.startTimeProject).toLocaleString() : '-';
    const leak = el?.leakageHistory ? el?.leakageHistory[el?.leakageHistory.length - 1]?.dateDenied : '';
    const leakDenied = el?.leakage?.dateDenied;
    const deniedDateDenied = leakDenied ? new Date(+leakDenied / 1e6).toLocaleString() : '-';
    const dateDenied = leak ? new Date(+leak / 1e6).toLocaleString()
      : '-';
    switch (typeOfProject) {
      case 'new':
        return projects.push({
          'Project name': name,
          'Organisation name': developer,
          'Project type': type,
          Surface: square,
          'Registration date': startProject,
          'Whitelisting application date': whiteListDate,
          'Denied date': dateDenied,
          'Project start date': startDate,
          Country: region,
        });
      case 'approve':
        return projects.push({
          'Project name': name,
          'Organisation name': developer,
          'Project type': type,
          Surface: square,
          'Registration date': startProject,
          'Whitelisting application date': whiteListDate,
          'Project start date': startDate,
          Carbon: 'Yes',
          Country: region,
        });
      case 'denied':
        return projects.push({
          'Project name': name,
          'Organisation name': developer,
          'Project type': type,
          Surface: square,
          'Registration date': startProject,
          'Whitelisting application date': whiteListDate,
          'Denied date': deniedDateDenied,
          'Project start date': startDate,
          Carbon: 'No',
          Country: region,
        });
      default:
        return {};
    }
  });

  return projects;
};

export const makeCsvForOrganisation = (data) => {
  const organisations = [];
  // eslint-disable-next-line array-callback-return
  data.map((org) => {
    const { near_account_id, organization, verified_date, ap_or_re_comment } = org;
    organisations.push({
      'Organization name': organization?.name,
      'Contact Person': organization?.contact_person,
      'NEAR account ID': near_account_id,
      Email: organization?.email,
      'Description of organization': organization?.description,
      Website: organization?.website,
      'Short description of the forest project(s)': organization?.short_description,
      'Countries in which the organization is active': organization?.countries.toString(),
      'Type of project': organization?.types.toString(),
      'How long have you been in operation/developing forest projects': organization?.days_in_project,
      'Date of approval': new Date(verified_date).toLocaleString(),
      Comment: ap_or_re_comment ? ap_or_re_comment.replace(/^"+|"+$/g, '') : '-',

    });
  });
  return organisations;
};
export const makeCsvForProtocols = (data) => {
  const protocols = [];
  // eslint-disable-next-line array-callback-return
  data.map((el) => {
    const { name, developer, type, project_id, region, square, carbonApplication } = el._source;
    const startProject = new Date(+project_id).toLocaleString();
    const whiteListDate = carbonApplication?.submittedAt ? new Date(+carbonApplication?.submittedAt / 1e6).toLocaleString() : '-';
    const startDate = el._source?.startTimeProject ? new Date(+el._source?.startTimeProject / 1e6).toLocaleString() : '-';
    const leak = el._source?.leakageHistory ? el._source?.leakageHistory[el._source?.leakageHistory.length - 1]?.dateDenied : '';
    const leakDenied = el._source?.leakage?.dateDenied;
    const deniedDateDenied = leakDenied ? new Date(+leakDenied / 1e6).toLocaleString() : '';
    const dateDenied = leak ? new Date(+leak / 1e6).toLocaleString() : '';
    const carbonAprrove = el._source?.leakage?.carbonApprove;
    protocols.push({
      'Project name': name,
      'Organisation name': developer,
      'Project type': type,
      Surface: square,
      'Registration date': startProject,
      'Whitelisting application date': whiteListDate,
      'Deny date': deniedDateDenied || dateDenied,
      'Project start date': startDate,
      Carbon: carbonAprrove ? 'Yes' : 'No',
      MRV: !carbonAprrove ? 'Yes' : 'No',
      Country: region,
    });
  });
  return protocols;
};
export const upNumberString = (input, up = 0.01, needNumber) => {
  const numberMatch = input.match(/^\d+\.\d+/);

  if (numberMatch) {
    const originalNumber = parseFloat(numberMatch[0]);
    const numberString = up.toString();
    const decimalPart = numberString.split('.')[1];
    const decimalLength = decimalPart ? decimalPart.length : 0;
    // Convert the matched string to a float
    const increasedNumber = (originalNumber + up).toFixed(decimalLength); // Increase by 1 and keep two decimal places

    // Replace the original number in the string with the new number
    const updatedString = input.replace(/^\d+\.\d+/, needNumber || increasedNumber);

    return updatedString; // Output: "3.13 How many tree species are you planting in the project zone?"
  }
  return input;
};
