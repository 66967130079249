import { storageKeys } from './constants';
import { runtimeMappings } from './runtime-mappings';
// import { runtimeMappings } from './runtime-mappings';

export const sendRequest = async (url, method, body, authRequired) => {
  const token = localStorage.getItem(storageKeys.token) || '';

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (authRequired) headers.append('Authorization', `Bearer ${token}`);

  const options = {};
  options.method = method;
  if (method !== 'GET') options.body = body;
  options.headers = headers;

  try {
    const response = await fetch(url, options);

    if (response.ok) {
      if (response.status === 204) return { result: null, statusCode: response.status };
      const json = await response.json();
      return { result: json, statusCode: response.status };
    }

    return { result: null, statusCode: response.status };
  } catch (_) {
    return { result: null, statusCode: 502 };
  }
};

export const getRequestProjectById = (id) => ({
  // need to future
  // runtime_mappings: { ...runtimeMappings },

  fields: [
    'currentPeriod',
    'currentVotingStage',
    'isProjectFinished',
  ],
  query: {
    bool: {
      must: [
        {
          match: {
            'project_id.keyword': id,
          },
        },
      ],
    },
  },
});

export const protocolInfoRequestBody = {
  runtime_mappings: { ...runtimeMappings },
  fields: ['startDateProject'],
  query: {
    bool: {
      must: [
        {
          terms: {
            status: ['active', 'register', 'paused'],
          },
        },
      ],
    },
  },
  from: 0,
  size: 30,
  _source: {
    exclude: ['stages', 'subZones', 'polygonCoordinate', 'sampleZones', 'stagesInfo'],
  },
};

export const newCarbonBodyRequest = {
  runtime_mappings: { ...runtimeMappings },
  fields: ['timeUntilValidation', 'mrvRang', 'submittedAtDate', 'isProjectFinished'],
  query: {
    bool: {
      must_not: [
        {
          terms: {
            status: ['terminated',
              'closed',
              'failed'],
          },
        },
        {
          exists: {
            field: 'leakage',
          },
        },
      ],
      must: [
        {
          match: {
            'carbonApplication.completed': true,
          },
        },
        {
          match: {
            isProjectFinished: false,
          },
        },
      ],
    },
  },
  from: 0,
  size: 20,
  _source: { excludes: 'stages' },
};
export const approveCarbonBodyRequest = {
  runtime_mappings: { ...runtimeMappings },
  fields: ['timeUntilValidation', 'isNewZone', 'mrvRang', 'nextValidation', 'isProjectFinished'],
  query: {
    bool: {
      must_not: [
        {
          terms: {
            status: ['terminated',
              'closed',
              'failed'],
          },
        },
      ],
      must: [
        {
          match: {
            'leakage.carbonApprove': true,
          },
        },
        {
          match: {
            isProjectFinished: false,
          },
        },
      ],
    },
  },
  from: 0,
  size: 20,
  _source: { excludes: 'stages' },
};
export const getInfoNewZonesCarbonBodyRequest = {
  runtime_mappings: { ...runtimeMappings },
  fields: ['timeUntilValidation', 'isNewZone', 'mrvRang', 'isProjectFinished'],
  query: {
    bool: {
      must_not: [
        {
          terms: {
            status: ['terminated',
              'closed',
              'failed'],
          },
        },
      ],
      must: [
        {
          match: {
            'leakage.carbonApprove': true,
          },
        },
        {
          match: {
            isNewZone: true,
          },
        },
        {
          match: {
            isProjectFinished: false,
          },
        },
      ],

    },
  },
  size: 0,
  _source: false,

};
export const deniedCarbonBodyRequest = {
  runtime_mappings: { ...runtimeMappings },
  fields: ['timeUntilValidation', 'isNewZone', 'deniedDate', 'mrvRang', 'isProjectFinished'],
  query: {
    bool: {
      must_not: [
        {
          terms: {
            status: ['terminated',
              'closed',
              'failed'],
          },
        },
      ],
      must: [
        {
          match: {
            'leakage.carbonApprove': false,
          },
        },
        {
          match: {
            isProjectFinished: false,
          },
        },
      ],

    },
  },
  from: 0,
  size: 20,
  _source: { excludes: 'stages' },

};
export const protocolInfoTotalHectares = {
  runtime_mappings: { ...runtimeMappings },
  fields: [
    'parsedSquare'],
  query: {
    bool: {
      must_not: [
      ],
      must: [
        {
          terms: {
            status: ['active', 'register', 'paused'],
          },
        },
      ],

    },
  },
  aggs: {
    sumHa: {
      sum: {
        field: 'parsedSquare',
      },
    },
  },
};

export const handleResponseStatus = (status, successFunc, failFunc) => {
  if (status >= 200 && status <= 299) successFunc();
  if (status >= 400 && status <= 499) {
    if (status === 401) window.location.pathname = '/login';
    else failFunc();
  }
  if (status >= 500 && status <= 599) failFunc();
};
