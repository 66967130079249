export const protocolInfoTableNames = [
  { name: 'Name', width: '20%' },
  { name: 'Organization', width: '20%' },
  { name: 'Project Type', width: '15%' },
  { name: 'Surface', width: '10%' },
  { name: '', width: '10%' },
  { name: 'Start Date', width: '10%' },
  { name: 'Country', width: '15%' },
];

export const validatorsTableNames = [
  { name: 'Email', width: '60%' },
  { name: 'NEAR account ID', width: '30%' },
];

export const permissionsTooltips = [
  { id: 'smart-contract', message: 'Ability to manage protocol' },
  { id: 'profile-management', message: 'Ability to manage organizations in smart contract' },
  { id: 'settings', message: 'Ability to change project settings (stage and periods length)' },
  { id: 'carbon', message: 'Ability to approve or cancel Carbon on project' },
];
